var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"annex"},[_c('div',{staticStyle:{"margin-bottom":"10px","width":"100%"}},[_c('div',[_vm._v(" 请选择你要新增的附件类型： "),_c('el-select',{staticClass:"item",staticStyle:{"width":"250px"},attrs:{"disabled":_vm.disabled,"placeholder":"请选择你要新增的附件类型","multiple":"","value-key":"dictVal"},model:{value:(_vm.Data),callback:function ($$v) {_vm.Data=$$v},expression:"Data"}},_vm._l((_vm.addDataList),function(item,i){return _c('el-option',{key:item.dictVal + i,attrs:{"label":item.dictName,"value":item}})}),1),_c('span',{staticStyle:{"width":"400px","margin-left":"1%"}},[_vm._v(" 新增自定义附件类型："),_c('el-input',{staticStyle:{"width":"200px"},attrs:{"disabled":_vm.disabled},nativeOn:{"keyup":function($event){return _vm.inputChange($event)}},model:{value:(_vm.addType),callback:function ($$v) {_vm.addType=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"addType"}}),(
            !_vm.disabled ||
            (_vm.options.postAttribute == 'DEPT_MANAGER' &&
              (_vm.options.status == 0 ||
                _vm.options.status == 5 ||
                _vm.options.status == 25 ||
                _vm.options.status == 35 ||
                _vm.options.status == 45))
          )?_c('i',{staticClass:"iconfont iconadd-circle",staticStyle:{"color":"#409eff","font-size":"30px","font-weight":"100","vertical-align":"middle","margin-left":"10px"},on:{"click":_vm.addTypeFn}}):_vm._e()],1)],1)]),_vm._l((_vm.Data),function(n,i){return [_c('el-card',{key:i},[_c('div',{staticClass:"annex-text",attrs:{"slot":"header"},slot:"header"},[_c('span',{staticClass:"clearfix"},[_vm._v(_vm._s(n.dictName))])]),_c('UploadList',{attrs:{"tableHeight":null,"disabled":_vm.disabled || n.disabled,"options":_vm.options,"fields":{
          name: 'fileName',
          path: 'filePath',
          type: 'type',
        },"value":n.res,"type":n.dictVal},on:{"change":_vm.change}})],1)]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }