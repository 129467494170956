<template>
  <div class="annex">
    <div style="margin-bottom: 10px; width: 100%">
      <div>
        请选择你要新增的附件类型：
        <el-select
          class="item"
          v-model="Data"
          :disabled="disabled"
          placeholder="请选择你要新增的附件类型"
          multiple
          value-key="dictVal"
          style="width: 250px"
        >
          <el-option
            v-for="(item, i) in addDataList"
            :label="item.dictName"
            :value="item"
            :key="item.dictVal + i"
          ></el-option>
        </el-select>

        <span style="width: 400px; margin-left: 1%">
          新增自定义附件类型：<el-input
            style="width: 200px"
            v-model.trim="addType"
            :disabled="disabled"
            @keyup.native="inputChange($event)"
          >
          </el-input>
          <i
            v-if="
              !disabled ||
              (options.postAttribute == 'DEPT_MANAGER' &&
                (options.status == 0 ||
                  options.status == 5 ||
                  options.status == 25 ||
                  options.status == 35 ||
                  options.status == 45))
            "
            @click="addTypeFn"
            style="
              color: #409eff;
              font-size: 30px;
              font-weight: 100;
              vertical-align: middle;
              margin-left: 10px;
            "
            class="iconfont iconadd-circle"
          ></i>
        </span>
      </div>
    </div>
    <template v-for="(n, i) in Data">
      <el-card :key="i">
        <div slot="header" class="annex-text">
          <span class="clearfix">{{ n.dictName }}</span>
        </div>
        <UploadList
          :tableHeight="null"
          :disabled="disabled || n.disabled"
          :options="options"
          :fields="{
            name: 'fileName',
            path: 'filePath',
            type: 'type',
          }"
          :value="n.res"
          @change="change"
          :type="n.dictVal"
        ></UploadList>
      </el-card>
    </template>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
  components: {
    UploadList: () => import('@/components/upload/List.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
  },
  props: {
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data() {
    return {
      addType: '',
      addDataList: [],
      Data: [],
      disabled: true,
      delTsFilDeleteList: [],
    }
  },
  inject: ['refresh'],
  computed: {
    ...mapState({
      projectInformation: state => state.project.projectInformation,
      projectFileList: state => state.project.projectFileList,
    }),
  },
  created() {
    // this.$api.dict
    //   .listSysDictData("Project_File", true)
    //   .then((res) => {
    //     res.data.some((v) => {
    //       if (v.id) {
    //         this.addDataList.push(v);
    //       }
    //     });
    //     this.addDataList.some((v) => {
    //       (v.disabled = false), (v.res = []);
    //     });
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  },
  watch: {
    options: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (options) {
        if (options.id) {
          this.disabled = false
          this.Data.some(v => {
            v.disabled = false
          })
          if (
            this.permission(['INFORMATIONEDIT']) === false ||
            options.isEditMonitoring === false
          ) {
            this.disabled = true
            this.Data.some(v => {
              v.disabled = true
            })
          } else if (this.permission(['PROJECTFILEEDIT'])) {
            if (
              options.isEditMonitoring &&
              options.status == 0 &&
              options.operatingButton.some(r => {
                return r == 'EDIT'
              })
            ) {
              this.disabled = false
              this.Data.some(v => {
                v.disabled = false
              })
            }
          }
        } else {
          this.disabled = false
          this.Data.some(v => {
            v.disabled = false
          })
        }
      },
    },
    addDataList: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal) {
        this.addDataList = this.fn2(newVal)
        newVal.some(v => {
          if (v.res.length > 0 && this.Data.indexOf(v) == -1) {
            this.Data.push(v)
            return
          }
        })
      },
    },
    projectFileList: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal) {
        this.$api.dict
          .listSysDictData('Project_File', true)
          .then(res => {
            res.data.some(v => {
              if (v.id) {
                this.addDataList.push(v)
              }
            })
            this.addDataList.some(v => {
              ;(v.disabled = false), (v.res = [])
            })

            newVal.some(r => {
              for (let i = 0; i < this.addDataList.length; i++) {
                let item = this.addDataList[i]
                if (r.type == item.dictVal) {
                  item.res.push(r)
                }
                if (r.typeName != item.dictName && r.type != item.dictVal) {
                  this.addDataList.push({
                    dictName: r.typeName,
                    dictVal: r.type,
                    disabled: false,
                    res: [],
                  })
                }

                if (r.typeName == item.dictName) {
                  let newobj = {} //去重
                  this.addDataList = this.addDataList.reduce((preVal, curVal) => {
                    newobj[curVal.dictName] ? '' : (newobj[curVal.dictName] = preVal.push(curVal))
                    return preVal
                  }, [])
                }
              }
            })
          })
          .catch(err => {
            console.log(err)
          })
      },
    },
  },

  destroyed() {
    this.Data = []
    this.addDataList = []
  },
  methods: {
    fn2(tempArr) {
      let newArr = []
      for (let i = 0; i < tempArr.length; i++) {
        if (newArr.indexOf(tempArr[i].dictName) == -1) {
          newArr.push(tempArr[i].dictName)
        } else {
          tempArr.splice(i, 1)
          i--
        }
      }
      return tempArr
    },
    inputChange(e) {
      const o = e.target
      o.value = o.value.replace(/[^\u4E00-\u9FA5]/g, '') // 清除除了中文以外的输入的字符
      this.addType = o.value
    },
    addTypeFn() {
      if (this.addType == '') return
      let isTrue = false
      this.addDataList.some(v => {
        if (v.dictName == this.addType) {
          this.$message({
            showClose: true,
            message: '已经有相同附件类型,请不要重复添加',
            type: 'warning',
          })
          isTrue = true
        }
      })
      if (!isTrue) {
        let obj = {
          dictName: this.addType,
          dictVal: this.addType.getPinyin(),
          disabled: false,
          res: [],
        }
        this.addDataList.push(obj)
        this.Data.push(obj)
        this.addType = ''
        isTrue = false
      }
    },
    change(r) {
      var arr = []
      this.Data.some(v => {
        v.res.some(r => {
          if (v.dictVal == r.type) {
            r.typeName = v.dictName
          }
          arr.push(r)
        })
      })
      this.$store.commit({
        type: 'project/SET_PROJECTFILELIST',
        projectFileList: arr,
      })
    },
  },
  beforeDestroy() {},
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
.annex {
  /deep/.el-card {
    //width:50%;
    .annex-text {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    + .el-card {
      margin-top: 20px;
    }
    .el-card__header {
      padding: 10px 15px;
      font-size: 16px;
      font-weight: bold;
    }
    .el-table {
      height: auto;
    }
  }
}
</style>
